/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CityApartmentsQuery } from "@/generated/graphql";
import { fitMapBounds } from "@/utils/fitMapBounds";
import { Box, Checkbox } from "@chakra-ui/react";
import { useAtom } from "jotai";
import useTranslation from "next-translate/useTranslation";
import * as React from "react";
import { AvailabilityMapPin } from "../AvailabilityMapPin";
import { BrandedPin } from "../BrandedPin";
import { selectedApartmentAtom } from "../CityApartments";
import { Map } from "../Map";
import type { MapProps } from "../Map/Map";

const createMapOptions = () => {
  return {
    panControl: false,
    scrollWheel: false,
    zoomControl: false,
    fullscreenControl: false,
  };
};

interface ApartmentsMapProps {
  branded?: boolean;
  listings: CityApartmentsQuery["listings"];
}

export const ApartmentsMap = ({ branded = true, listings, ...rest }: ApartmentsMapProps & MapProps) => {
  const { t } = useTranslation("homes");
  const [map, setMap] = React.useState<any>();
  const [showAvailableOnly, setShowAvailableOnly] = React.useState(false);
  const [selectedApartment] = useAtom(selectedApartmentAtom);

  const listingsWithPins = listings.filter((listing) => listing.apartmentLatitude && listing.apartmentLongitude);
  const pins = listingsWithPins.map((listing) => ({
    lat: listing.apartmentLatitude!,
    lng: listing.apartmentLongitude!,
  }));

  const availableListings = listingsWithPins.filter((listing) => listing.available);
  const unavailableListings = listingsWithPins.filter((listing) => !listing.available);

  const onLoad = React.useCallback(({ map }) => setMap(map), []);

  React.useEffect(() => {
    // We need to fit map bounds every time the filter changes
    if (map) {
      fitMapBounds({ map, pins });
    }
  }, [map, pins]);

  return (
    <Map
      w={{ base: "100%", xl: "524px" }}
      h={{ base: "343px", sm: "448px", md: "516px", lg: "684px", xl: "400px" }}
      zoom={10}
      center={{ lat: 53, lng: 13 }}
      options={createMapOptions}
      borderRadius="lg"
      overflow="hidden"
      onGoogleApiLoaded={onLoad}
      yesIWantToUseGoogleMapApiInternals
      position="relative"
      {...rest}
      actions={
        <Box
          bg="white"
          borderRadius="base"
          py={2}
          px={3}
          maxW="max-content"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.16)"
          position="absolute"
          top={6}
          left={0}
          right={0}
          mx="auto"
          zIndex={1}
        >
          <Checkbox
            spacing={3}
            color={showAvailableOnly ? "gray.900" : "gray.600"}
            colorScheme="green"
            onChange={() => setShowAvailableOnly(!showAvailableOnly)}
          >
            {t("show-available-only")}
          </Checkbox>
        </Box>
      }
    >
      {showAvailableOnly
        ? null
        : unavailableListings.map((listing) => {
            return branded ? (
              <BrandedPin
                key={listing.apartmentId}
                lat={listing.apartmentLatitude as number}
                lng={listing.apartmentLongitude as number}
              />
            ) : (
              <AvailabilityMapPin
                key={listing.apartmentId}
                lat={listing.apartmentLatitude as number}
                lng={listing.apartmentLongitude as number}
                apartment={listing}
                selected={listing.apartmentId === selectedApartment}
              />
            );
          })}
      {availableListings.map((listing) => {
        return branded ? (
          <BrandedPin
            key={listing.apartmentId}
            lat={listing.apartmentLatitude as number}
            lng={listing.apartmentLongitude as number}
          />
        ) : (
          <AvailabilityMapPin
            key={listing.apartmentId}
            lat={listing.apartmentLatitude as number}
            lng={listing.apartmentLongitude as number}
            apartment={listing}
            selected={listing.apartmentId === selectedApartment}
          />
        );
      })}
    </Map>
  );
};
