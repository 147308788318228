import { ApartmentFilter, FilterData } from "@/components/ApartmentFilter";
import { ApartmentsMap } from "@/components/ApartmentsMap";
import { CityApartmentsQuery } from "@/generated/graphql";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import {
  Button,
  Flex,
  StackDivider,
  StackProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useSetAtom } from "jotai";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { ApartmentCard } from "../ApartmentCard";
import { ApartmentCardLoading } from "../ApartmentCardLoading";
import { BrowseSignupCta } from "../BrowseSignupCta";
import { selectedApartmentAtom } from "./selectedApartmentAtom";

// Rest of the world does not use mm-dd-yyyy so let's not either
function getFormattedDate(date: Date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

interface ApartmentStackProps {
  listingsAvailable: CityApartmentsQuery["listings"];
  listingsUnavailable: CityApartmentsQuery["listings"];
  isLoading: boolean;
}

const ApartmentStack = React.memo(function ApartmentStack({
  listingsAvailable,
  listingsUnavailable,
  isLoading,
  ...rest
}: ApartmentStackProps & StackProps) {
  const router = useRouter();
  const { reset, getValues } = useFormContext();
  const setSelectedApartment = useSetAtom(selectedApartmentAtom);
  const { t } = useTranslation("homes");

  const handleReset = () => {
    router.push({ query: { uid: router.query.uid } }, undefined, { shallow: true });

    reset({ category: null, availableFrom: null, maxPrice: null, type: null });
  };

  const filtering = Object.values(getValues()).some((value) => value !== null);
  const imageSizes = { base: "100vw", md: "50vw", lg: "25vw" };

  return (
    <VStack spacing={6} divider={<StackDivider borderColor="gray.400" />} align="stretch" {...rest}>
      {isLoading ? (
        Array.from({ length: 10 }, (_, i) => i + 1).map((i) => (
          <ApartmentCardLoading key={i} badge orientation="horizontal" />
        ))
      ) : listingsAvailable.length > 0 ? (
        listingsAvailable.map((listing) => (
          <ApartmentCard
            id={`apartment-${listing.apartmentId}`}
            key={listing.apartmentId}
            apartment={listing}
            orientation="horizontal"
            withBadge
            onMouseEnter={() => setSelectedApartment(listing.apartmentId)}
            onMouseLeave={() => setSelectedApartment(null)}
            imageSizes={imageSizes}
          />
        ))
      ) : (
        <>
          {filtering && (
            <Flex alignItems="baseline" flexDirection={{ base: "column", md: "row" }}>
              {t("empty-state")}
              <Button ml={{ md: 2 }} color="almond" variant="unstyled" onClick={handleReset} p={0}>
                {t("reset-filter")}
              </Button>
            </Flex>
          )}
        </>
      )}
      <BrowseSignupCta numAvailable={listingsAvailable.length} />
      {isLoading
        ? null
        : listingsUnavailable.map((apartment) => (
            <ApartmentCard
              id={`apartment-${apartment.apartmentId}`}
              key={apartment.apartmentId}
              apartment={apartment}
              orientation="horizontal"
              withBadge
              onMouseEnter={() => setSelectedApartment(apartment.apartmentId)}
              onMouseLeave={() => setSelectedApartment(null)}
              imageSizes={imageSizes}
            />
          ))}
    </VStack>
  );
});

export interface CityApartmentsProps extends StackProps {
  listings: CityApartmentsQuery["listings"];
  isLoading: boolean;
  tabs?: string[];
}

export const CityApartments = ({
  listings,
  isLoading,
  tabs = ["list-view", "map-view"],
  ...rest
}: CityApartmentsProps) => {
  const router = useRouter();
  const { t } = useTranslation("homes");
  const isLargeScreen = useBreakpointValue({
    base: false,
    lg: true,
  });

  const onSubmit = (data: Partial<FilterData>) => {
    const { query } = router;
    for (const [key, value] of Object.entries(data)) {
      if (!value) {
        delete query[key];
      } else {
        query[key] = value instanceof Date ? getFormattedDate(value) : (value as string);
      }
    }

    router.push({ query }, undefined, { shallow: true });
  };

  let listingsAvailable: CityApartmentsQuery["listings"] = [];
  let listingsUnavailable: CityApartmentsQuery["listings"] = [];

  if (listings.length > 0) {
    listingsAvailable = listings
      .filter((listing) => listing.apartmentName && listing.available)
      .sort((a, b) => (a.availableFrom ? a.availableFrom.localeCompare(b.availableFrom) : 1));

    listingsUnavailable = listings.filter((listing) => listing.apartmentName && !listing.available);
  }

  const onTabChange = (index: number) => {
    if (tabs[index]) {
      logEvent(AMPLITUDE_EVENTS.apartmentListTabClick, { tab: tabs[index] });
    }
  };

  return (
    <>
      <ApartmentFilter mb={10} onSubmit={onSubmit} />
      {!isLargeScreen && (
        <Tabs onChange={onTabChange} isLazy>
          <TabList>
            <Tab>{t(tabs[0])}</Tab>
            <Tab>{t(tabs[1])}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel pt={10} pb="0 !important">
              <ApartmentStack
                listingsAvailable={listingsAvailable}
                listingsUnavailable={listingsUnavailable}
                isLoading={isLoading || !router.isReady}
                {...rest}
              />
            </TabPanel>
            <TabPanel pt={10} pb="0 !important">
              <ApartmentsMap
                branded={false}
                listings={listings}
                w="100%"
                h="calc(100vh - 72px - 80px - 80px)"
                position="relative"
                zoom={17}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
      {isLargeScreen && (
        <ApartmentStack
          listingsAvailable={listingsAvailable}
          listingsUnavailable={listingsUnavailable}
          isLoading={isLoading || !router.isReady}
          {...rest}
        />
      )}
    </>
  );
};
