import { ApartmentPopoverApartmentFragment } from "@/generated/graphql";
import { formatPrice } from "@/utils/formatPrice";
import { getApartmentImages } from "@/utils/getApartmentImages";
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { gql } from "graphql-tag";
import NextLink from "next/link";
import { Carousel } from "../Carousel";

export const APARTMENT_POPOVER_APARTMENT_FRAGMENT = gql`
  fragment ApartmentPopoverApartment on ListingType {
    availableFrom
    availableRooms
    citySlug
    currency
    apartmentName
    apartmentSuburb
    apartmentSlug
    minPrice
    startingPrice
    apartmentCmsData
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApartmentPopoverProps extends PopoverProps {
  apartment: ApartmentPopoverApartmentFragment;
}

export const ApartmentPopover = ({ apartment, children, ...rest }: ApartmentPopoverProps) => {
  const images = getApartmentImages({ apartment, width: 320 });

  const hasAvailability = apartment.availableRooms ?? 0 > 0;

  return (
    <Popover placement="top" closeOnBlur isLazy {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent borderRadius="2xl">
        <PopoverArrow />
        <PopoverBody p={4}>
          {images && (
            <Carousel
              _hover={{
                ".carousel-button": {
                  opacity: 0.9,
                },
              }}
              images={images}
              height="200px"
              width="100%"
              zIndex={1}
              imageSizes="720px"
            />
          )}
          <Box mt={4} position="relative">
            <Text fontFamily="Inter" fontSize="xs" fontWeight="medium" color="almond" mb={1} textTransform="uppercase">
              {apartment.apartmentSuburb}
            </Text>
            <Text fontFamily="Fraunces" fontSize="xl" mb={hasAvailability ? 2 : 0} fontWeight="semibold">
              {apartment.apartmentName}
            </Text>
            {hasAvailability ? (
              <Text fontFamily="Inter" fontSize="sm" color="gray.600">
                Monthly from{" "}
                <Text fontFamily="Inter" fontSize="sm" as="span" color="gray.900" fontWeight="medium">
                  {formatPrice(apartment.startingPrice || apartment.minPrice || 0, apartment.currency)}
                </Text>
              </Text>
            ) : null}
            <Box position="absolute" top={0} right={0}>
              <NextLink href={`/${apartment.citySlug}/homes/${apartment.apartmentSlug}`} passHref legacyBehavior>
                <Button as="a" _hover={{ textDecoration: "none" }} variant="primary" size="sm" fontFamily="Inter">
                  View rooms
                </Button>
              </NextLink>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
