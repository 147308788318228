import React from "react";
import type { ReactDatePickerProps } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box } from "@chakra-ui/react";

export interface DatePickerProps extends ReactDatePickerProps {
  isClearable?: boolean;
  onChange: (date: Date) => any;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
  placeholderText?: string;
}

export const DatePicker = ({
  selectedDate,
  onChange,
  isClearable = true,
  showPopperArrow = false,
  placeholderText = "Select date",
  ...rest
}: DatePickerProps) => {
  return (
    <Box
      css={`
        .react-datepicker {
          font-family: unset;
          font-size: 0.9rem;
          border-color: var(--chakra-colors-gray-200);
          overflow: hidden;
        }

        .react-datepicker-popper {
          z-index: 69;
        }

        .react-datepicker-wrapper,
        .react-datepicker__input-container {
          display: block;
        }

        .react-datepicker__input-container {
          font-size: 1rem;
          padding-left: 1rem;
          padding-right: 1rem;
          height: 2.5rem;
          border-radius: var(--chakra-radii-md);
          border: 1px solid;
          border-color: var(--chakra-colors-gray-400);
          box-shadow: var(--chakra-shadows-sm);
        }
        .react-datapicker__input-text {
          background-color: transparent;
        }

        .react-datapicker__input-text::placeholder {
          color: var(--chakra-colors-gray-500);
        }

        .react-datepicker__input-container:hover {
          border-color: var(--chakra-colors-gray-300);
        }
        .react-datepicker__input-container:focus-within {
          z-index: 1;
          border-color: var(--chakra-colors-almond);
          box-shadow: 0 0 0 1px var(--chakra-colors-almond);
        }

        .react-datepicker__input-container > input {
          width: 100%;
          height: 100%;
          outline: 0;
        }

        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
          right: 90px;
        }

        .react-datepicker__navigation--previous,
        .react-datepicker__navigation--next {
          height: min-content;
          margin-top: 6px;
        }

        .react-datepicker__navigation--previous {
          border-right-color: var(--chakra-colors-gray-300);
        }

        .react-datepicker__navigation--previous:hover {
          border-right-color: var(--chakra-colors-gray-400);
        }

        .react-datepicker__navigation--next {
          border-left-color: var(--chakra-colors-gray-300);
        }

        .react-datepicker__navigation--next:hover {
          border-left-color: var(--chakra-colors-gray-400);
        }

        .react-datepicker__header {
          background-color: var(--chakra-colors-gray-50);
        }

        .react-datepicker__header,
        .react-datepicker__time-container {
          border-color: var(--chakra-colors-gray-200);
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
          font-size: inherit;
          font-weight: 500;
          color: var(--chakra-colors-black);
        }

        .react-datepicker__month {
          background-color: var(--chakra-colors-white);
          margin: 0;
          padding: 0.4rem;
        }

        .react-datepicker__time-container
          .react-datepicker__time
          .react-datepicker__time-box
          ul.react-datepicker__time-list
          li.react-datepicker__time-list-item {
          margin: 0 1px 0 0;
          height: auto;
          padding: 7px 10px;
        }

        .react-datepicker__time-container
          .react-datepicker__time
          .react-datepicker__time-box
          ul.react-datepicker__time-list
          li.react-datepicker__time-list-item:hover {
          background: var(--chakra-colors-gray-50);
        }

        .react-datepicker__day {
          color: var(--chakra-colors-black);
        }

        .react-datepicker__day:hover {
          background: var(--chakra-colors-gray-200);
        }

        .react-datepicker__day--keyboard-selected:hover {
          background: var(--chakra-colors-almond);
        }

        .react-datepicker__day-name {
          color: var(--chakra-colors-black);
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__time-container
          .react-datepicker__time
          .react-datepicker__time-box
          ul.react-datepicker__time-list
          li.react-datepicker__time-list-item--selected {
          background: var(--chakra-colors-almond);
          font-weight: normal;
          color: var(--chakra-colors-white);
        }

        .react-datepicker__time-container
          .react-datepicker__time
          .react-datepicker__time-box
          ul.react-datepicker__time-list
          li.react-datepicker__time-list-item--selected:hover {
          background: var(--chakra-colors-almond);
        }

        .react-datepicker__close-icon {
          color: var(--chakra-colors-gray-200);
          padding-right: 8px;
        }

        .react-datepicker__close-icon:hover {
          color: var(--chakra-colors-gray-300);
        }

        .react-datepicker__close-icon::after {
          background-color: unset;
          border-radius: unset;
          font-size: 1.5rem;
          font-weight: bold;
          color: inherit;
          height: 24px;
          width: 24px;
        }
      `}
    >
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        className="react-datapicker__input-text"
        placeholderText={placeholderText}
        dateFormat="yyyy/MM/dd"
        calendarStartDay={1}
        {...rest}
      />
    </Box>
  );
};
