import { ApartmentPopover, APARTMENT_POPOVER_APARTMENT_FRAGMENT } from "@/components/ApartmentPopover";
import type { AvailabilityMapPinApartmentFragment } from "@/generated/graphql";
import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { gql } from "graphql-tag";
import * as React from "react";
import MapPinSvg from "../../../public/map-pin-v2.svg";

export const AVAILABILITY_MAP_PIN_APARTMENT_FRAGMENT = gql`
  fragment AvailabilityMapPinApartment on ListingType {
    apartmentId
    availableFrom
    apartmentLatitude
    apartmentLongitude
    ...ApartmentPopoverApartment
  }
  ${APARTMENT_POPOVER_APARTMENT_FRAGMENT}
`;

interface AvailabilityMapPinProps {
  apartment: AvailabilityMapPinApartmentFragment;
  lat?: number;
  lng?: number;
  selected?: boolean;
}

export const AvailabilityMapPin = React.memo(function AvailabilityMapPin({
  apartment,
  selected = false,
  ...rest
}: AvailabilityMapPinProps & BoxProps) {
  let color: string;
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const available = Boolean(apartment.availableFrom);

  if (selected && available) {
    color = "green.900";
  } else if (selected && !available) {
    color = "red.900";
  } else if (available) {
    color = "green.600";
  } else {
    color = "red.600";
  }

  return (
    <Box position="relative" zIndex={selected ? 1 : undefined} {...rest}>
      <ApartmentPopover apartment={apartment} isOpen={isOpen} onClose={close}>
        <Box
          as="button"
          type="button"
          position="absolute"
          opacity={0}
          transform="translate(-16px, -16px)"
          transformOrigin="50% 50%"
        >
          <MapPinSvg />
        </Box>
      </ApartmentPopover>
      <Box
        as="button"
        type="button"
        color={color}
        _hover={{ color: available ? "green.900" : "red.900" }}
        transform={`translate(-16px, -16px) ${selected ? "scale(1.077)" : ""}`}
        transformOrigin="50% 50%"
        cursor="pointer"
        transitionProperty="color, transform"
        transitionDuration="faster"
        onClick={open}
        willChange="color, transform"
      >
        <MapPinSvg />
      </Box>
    </Box>
  );
});
