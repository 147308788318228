export const formatPrice = (price: number, currency?: string): string => {
  const formattedPrice = new Intl.NumberFormat().format(price);

  switch (currency) {
    case "DKK":
      return `DKK ${formattedPrice}`;
    case "GBP":
      return `£${formattedPrice}`;
    default:
      return `€${formattedPrice}`;
  }
};
