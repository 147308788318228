import { Card } from "@/components/common";
import type { CardProps } from "@/components/common/Card";
import { useFilter } from "@/hooks/useFilter";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import { Button, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import NextLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

export interface BrowseSignupCtaProps extends CardProps {
  numAvailable: number;
}

export const BrowseSignupCta = ({ numAvailable, ...rest }: BrowseSignupCtaProps) => {
  const { t } = useTranslation("homes");
  const router = useRouter();
  const [, setFilter] = useFilter();

  const buttonClick = () => {
    const query = router.query;

    logEvent(numAvailable === 0 ? AMPLITUDE_EVENTS.subscribeButtonClick : AMPLITUDE_EVENTS.signupButtonClick, {
      origin: "Browse signup CTA card",
      city: router.query.uid,
      ...query,
    });

    setFilter(query);
  };

  return (
    <Card bg="cream" {...rest}>
      <Text fontSize="xl" fontWeight="medium" mb={4}>
        {t("signup-cta.title", { count: numAvailable })}
      </Text>
      <Text mb={8}>{t("signup-cta.body", { count: numAvailable })}</Text>
      <NextLink
        href={
          numAvailable === 0
            ? { pathname: "/[uid]/subscribe", query: { uid: router.query.uid } }
            : { pathname: "/[uid]/signup", query: { uid: router.query.uid } }
        }
        passHref
        legacyBehavior
      >
        <Button onClick={buttonClick} as="a">
          {t("signup-cta.button", { count: numAvailable })}
        </Button>
      </NextLink>
    </Card>
  );
};
