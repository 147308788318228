export const fitMapBounds = ({ map, pins }: { map: any; pins: { lat: number; lng: number }[] }) => {
  if (!window) return;

  const gmaps = (window as any).google.maps;
  const bounds = new gmaps.LatLngBounds();

  for (const { lat, lng } of pins) {
    bounds.extend(new gmaps.LatLng(lat + 0.01, lng + 0.01));
    bounds.extend(new gmaps.LatLng(lat - 0.01, lng - 0.01));
  }

  map.fitBounds(bounds);
};
