import { DatePicker } from "@/components/DatePicker";
import { UnitCategory, UnitType } from "@/generated/graphql";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import type { GridProps } from "@chakra-ui/react";
import { FormControl, FormLabel, Grid, Input, Select } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Controller, useFormContext } from "react-hook-form";

export interface FilterData {
  category: UnitCategory | null;
  availableFrom: Date | string | null;
  maxPrice: number | null;
  type: UnitType | null;
}

export interface ApartmentFilterProps extends Omit<GridProps, "onSubmit"> {
  onSubmit: (data: Partial<FilterData>) => void;
}

export const ApartmentFilter = ({ onSubmit, ...rest }: ApartmentFilterProps) => {
  const { t } = useTranslation("homes");
  const router = useRouter();
  const citySlug = router.query.uid;

  const { control, register } = useFormContext();

  return (
    <Grid
      gridTemplateColumns={{ base: "repeat(2, minmax(0, 1fr))", md: "repeat(4, minmax(0, 1fr))" }}
      gridGap={4}
      alignItems="flex-end"
      {...rest}
    >
      <FormControl>
        <FormLabel fontSize="sm" htmlFor="moveInDate">
          {t("filter.move-in-date.label")}
        </FormLabel>
        <Controller
          control={control}
          name="availableFrom"
          render={({ field }) => (
            <DatePicker
              placeholderText={t("filter.move-in-date.placeholder")}
              onChange={(date) => {
                field.onChange(date);
                logEvent(AMPLITUDE_EVENTS.moveInDateChanged, { date, citySlug });
                onSubmit({ availableFrom: date });
              }}
              selectedDate={field.value}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="sm" htmlFor="maxPrice">
          {t("filter.max-price.label")}
        </FormLabel>
        <Input
          placeholder={t("filter.max-price.placeholder")}
          type="number"
          {...register("maxPrice")}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              logEvent(AMPLITUDE_EVENTS.maxPriceChanged, { maxPrice: event.currentTarget.value, citySlug });
              onSubmit({ maxPrice: Number.parseInt(event.currentTarget.value, 10) });
            }
          }}
          onBlur={(event) => onSubmit({ maxPrice: Number.parseInt(event.target.value, 10) })}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="sm" htmlFor="type">
          {t("filter.unit-type.label")}
        </FormLabel>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              borderColor="gray.400"
              {...register("type")}
              onChange={(event) => {
                field.onChange(event);
                logEvent(AMPLITUDE_EVENTS.unitTypeChanged, { unitType: event.currentTarget.value, citySlug });
                onSubmit({ type: event.target.value as UnitType });
              }}
            >
              <option value="">{t("filter.unit-type.all")}</option>
              <option value={UnitType.Entire}>{t("filter.unit-type.entire")}</option>
              <option value={UnitType.Shared}>{t("filter.unit-type.shared")}</option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="sm" htmlFor="category">
          {t("filter.room-type.label")}
        </FormLabel>
        <Controller
          control={control}
          name="category"
          render={({ field }) => (
            <Select
              borderColor="gray.400"
              {...register("category")}
              onChange={(event) => {
                field.onChange(event);
                logEvent(AMPLITUDE_EVENTS.roomTypeChanged, { roomType: event.currentTarget.value, citySlug });
                onSubmit({ category: event.target.value as UnitCategory });
              }}
            >
              <option value="">{t("filter.room-type.all")}</option>
              <option value={UnitCategory.Single}>{t("filter.room-type.single")}</option>
              <option value={UnitCategory.Couple}>{t("filter.room-type.couple")}</option>
            </Select>
          )}
        />
      </FormControl>
    </Grid>
  );
};
